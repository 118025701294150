<nb-card>
    <nb-card-header>Reset Password</nb-card-header>
    <nb-card-body>
      <div [formGroup]="form" aria-labelledby="title" class="mt-1">
        <div class="form-control-group">
          <label for="input-password">Password</label>
          <div class="input-group w-100"
            style="border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.42); border-radius: 0.25rem;">
            <input  nbInput
              type="password"
              id="input-password"
              #pwd
              class="flex-grow-1"
              style="max-width: none; border-width: 0px"
              formControlName="password"
              [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
              [attr.aria-invalid]="password.invalid && password.touched ? true : null">
            <div class="input-group-append">
              <span class="input-group-text"
                style="background-color: transparent; border-style: none;">
                <i
                  class="fa"
                  style="width: 20px;"
                  [ngClass]="{
                    'fa-eye-slash': pwd.type=='password',
                    'fa-eye': pwd.type!='password'
                  }"
                  (click)="pwd.type=pwd.type=='password'?'text':'password'"
                ></i>
              </span>
            </div>
          </div>
          <ngx-validation-message 
            label="Password" 
            [showRequired]="password.errors?.required"
            [showMinLength]="password.errors?.minlength"
            [showPattern]="password.errors?.pattern"
            [noUppercaseOrLowercase]="password.errors?.noUppercaseOrLowercase"
            [noNumeric]="password.errors?.noNumeric"
            [minLength]="6"
            ></ngx-validation-message>
        </div>
        <div class="form-control-group">
          <label for="input-confirm">Confirm Password</label>
          <div class="input-group w-100"
            style="border-style: solid; border-width: 1px; border-color: rgba(0, 0, 0, 0.42); border-radius: 0.25rem;">
            <input  nbInput
              type="password"
              id="input-confirm"
              #confpwd
              class="flex-grow-1"
              style="max-width: none; border-width: 0px"
              formControlName="confirm"
              [status]="confirm.dirty ? (confirm.invalid  ? 'danger' : 'success') : 'basic'"
              [attr.aria-invalid]="confirm.invalid && confirm.touched ? true : null">
            <div class="input-group-append">
              <span class="input-group-text"
                style="background-color: transparent; border-style: none;">
                <i
                  class="fa"
                  style="width: 20px;"
                  [ngClass]="{
                    'fa-eye-slash': confpwd.type=='password',
                    'fa-eye': confpwd.type!='password'
                  }"
                  (click)="confpwd.type=confpwd.type=='password'?'text':'password'"
                ></i>
              </span>
            </div>
          </div>

          <ngx-validation-message 
            label="Confirm Password" 
            [showRequired]="confirm.errors?.required"
            [showMinLength]="confirm.errors?.minlength"
            [noUppercaseOrLowercase]="confirm.errors?.noUppercaseOrLowercase"
            [noNumeric]="confirm.errors?.noNumeric"
            [notMatch]="form.errors?.notMatch"
            [minLength]="6"
            ></ngx-validation-message>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button class="cancel" matRipple nbButton status="danger" (click)="cancel()">Cancel</button>
      <button nbButton matRipple status="success" (click)="save()" [disabled]="!form.valid">Save</button>
    </nb-card-footer>
  </nb-card>