<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Furn<span style="color: black;">Serve</span></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item" title="Search">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="message-circle-outline" (click)="onChatActionClick()" [badgeText]="msgCount>0?msgCount:null"
    badgeStatus="danger" badgePosition="top right" title="Messages"></nb-action>
    <nb-action class="control-item" icon="bell-outline" title="Events"></nb-action>
    <nb-action class="user-action"  *nbIsGranted="['view', 'current-user']" >
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="user-context-menu" id="mainAvatar"
               [name]="user.firstname + ' ' + user.lastname"
               [picture]="photo"
               [badgeStatus]="status"
               badgePosition="bottom right"
               badgeText=" "
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
